import { useEffect, useState } from "react";
import { noop } from "lodash";
import {
  extractHtmlContent,
  isHtmlContent,
} from "@circle-react/helpers/htmlContent";
import { SanitizeHTMLString } from "@circle-react-uikit/SanitizeContent";
import { FadingHtmlNode } from "./FadingHtmlNode";
import { FadingWord } from "./FadingWord";
import { type HtmlNode, parseHtmlNodeTree } from "./HtmlNode";

interface FadingTextEffectProps {
  text: string;
  updateInterval?: number;
  active?: boolean;
  onComplete?: () => void;
}

export const FadingTextEffect = ({
  text,
  updateInterval = 60,
  active = true,
  onComplete = noop,
}: FadingTextEffectProps) => {
  const [currentTokenIndex, setCurrentTokenIndex] = useState(0);
  const [htmlTree, setHtmlTree] = useState<HtmlNode[]>([]);
  const [words, setWords] = useState<string[]>([]);
  const [totalWords, setTotalWords] = useState(0);

  useEffect(() => {
    if (text && active) {
      if (isHtmlContent(text)) {
        const htmlContent = extractHtmlContent(text);
        const { tree, totalWords } = parseHtmlNodeTree(htmlContent);
        setHtmlTree(tree);
        setTotalWords(totalWords);
      } else {
        const words = text.match(/\S+\s*/g) || [];
        setWords(words);
        setTotalWords(words.length);
      }

      setCurrentTokenIndex(0);
    } else {
      setHtmlTree([]);
      setWords([]);
      setTotalWords(0);
      onComplete();
    }
  }, [text, active, onComplete]);

  useEffect(() => {
    if (!totalWords || currentTokenIndex >= totalWords) {
      if (totalWords && currentTokenIndex >= totalWords) {
        onComplete();
      }
      return;
    }

    const timer = setTimeout(() => {
      setCurrentTokenIndex(prev => prev + 1);
    }, updateInterval);

    return () => clearTimeout(timer);
  }, [totalWords, currentTokenIndex, updateInterval, onComplete]);

  if (active) {
    return (
      <>
        {htmlTree.length > 0 ? (
          htmlTree.map((node, index) => (
            <FadingHtmlNode
              key={[node.type, index].join("-")}
              node={node}
              currentTokenIndex={currentTokenIndex}
            />
          ))
        ) : (
          <span className="whitespace-pre-wrap">
            {words.map((token, index) => (
              <FadingWord
                key={[token, index].join("-")}
                isVisible={index < currentTokenIndex}
              >
                {token}
              </FadingWord>
            ))}
          </span>
        )}
      </>
    );
  }

  return (
    <SanitizeHTMLString
      content={extractHtmlContent(text)}
      className="whitespace-pre-wrap"
    />
  );
};
