import { classNames } from "@/react/helpers/twMergeWithCN";
import { Typography } from "@circle-react-uikit/Typography";
import { FadingTextEffect } from "../FadingTextEffect";
import { TypingEffect } from "../TypingEffect";

interface AiSummaryBodyTextProps {
  animation?: "none" | "typing" | "fade-in";
  onAnimationComplete?: () => void;
  children: string;
  containerClassName?: string;
}

export const AiSummaryBodyText = ({
  animation = "none",
  onAnimationComplete,
  containerClassName,
  children,
}: AiSummaryBodyTextProps) => (
  <div
    className={classNames(
      "[&_ol]:list-decimal [&_ol]:pl-5 [&_p+ol]:py-4 [&_p+ul]:py-4 [&_ul]:list-outside [&_ul]:list-disc [&_ul]:space-y-2 [&_ul]:pl-5",
      containerClassName,
    )}
  >
    <Typography.BodySm color="text-dark">
      {animation === "typing" ? (
        <TypingEffect text={children} onComplete={onAnimationComplete} />
      ) : animation === "fade-in" ? (
        <FadingTextEffect text={children} onComplete={onAnimationComplete} />
      ) : (
        <span className="whitespace-pre-wrap">{children}</span>
      )}
    </Typography.BodySm>
  </div>
);
